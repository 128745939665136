import React, { useMemo, useState } from "react";
import DataTable from "react-data-table-component";
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardHeader,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import { PrimeSearchInput } from "components";
import { RefreshButton } from "components/atoms/RefreshButton";
import { customStyles } from "utils/datatableStyles";
import { history } from "utils";
import { useParams } from "react-router";
import { useDeviceGatewayGroupsDatatable } from "../hooks/useDeviceGatewayGroupsDatatable";
import { mapTriggerLevelDisplay } from "../utils";
import { orderBy } from "lodash";
import { useDeleteGatewayGroup } from "../hooks/useDeleteGatewayGroup";

const GatewayGroupTabPanel = () => {
  const { sn } = useParams();
  const [smartSearch, setSmartSearch] = useState("");
  const {
    data,
    isLoading,
    isFetching,
    pageSize,
    totalRows,
    onChangePage,
    onChangeRowsPerPage,
    onSort,
    refetch
  } = useDeviceGatewayGroupsDatatable({
    sn,
    smartSearch: smartSearch && smartSearch !== "" ? smartSearch : undefined,
  });
  const onDelete = useDeleteGatewayGroup(sn);

  const columns = useMemo(() => {
    const renderTriggerLevel = ({ trigger }) => {
      return trigger ? mapTriggerLevelDisplay[trigger] : "-";
    };

    const renderGateway = (row) => {
      const { deviceNetworkGatewayGroupItems } = row;
      const sortByPriority = deviceNetworkGatewayGroupItems
        ? orderBy(deviceNetworkGatewayGroupItems, "priority")
        : [];
      const gateways = sortByPriority?.map((gw) => {
        return {
          priority: gw.priority,
          name: gw.deviceNetworkGateway?.name ?? "-",
          status: gw.deviceNetworkGateway?.statusTranslated ?? null,
        };
      });

      return (
        <div>
          {gateways
            ? gateways.map((gw, i) => {
                const color =
                  gw.status?.toLowerCase() === "online" ? "#2DCE89" : "#F5365C";
                return (
                  <div
                    key={`gw-item-${i}`}
                    className="d-flex align-items-center"
                  >
                    <div className="text-muted mr-3" style={{ width: 50 }}>
                      Tier {gw.priority}
                    </div>
                    <div className="d-flex align-items-center">
                      <Badge className="badge-dot mr-1">
                        <i style={{ backgroundColor: color}}/>
                        <span className="text-dark">{gw.name}</span>
                      </Badge>
                    </div>
                  </div>
                );
              })
            : null}
        </div>
      );
    };

    const renderAction = (data) => {
      const openConfiguration = () => {
        history.push(
          `/admin/sdwan/device/${sn}/gateways/groups/configuration/${data.id}/${data.idx}`
        );
      };

      return (
        <div className="mr-auto">
          <UncontrolledDropdown nav>
            <DropdownToggle nav>
              <i className="fas fa-ellipsis-v" style={{ fontSize: 14 }}></i>
            </DropdownToggle>
            <DropdownMenu right>
              <DropdownItem onClick={openConfiguration}>
                Configuration
              </DropdownItem>
              <DropdownItem onClick={() => onDelete(data)}>Delete</DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </div>
      );
    };

    return [
      {
        id: "name",
        name: "Name",
        selector: (row) => row.name,
        sortable: true,
      },
      {
        id: "trigger",
        name: "Trigger",
        selector: (row) => row.trigger,
        cell: renderTriggerLevel,
        sortable: true,
      },
      {
        id: "gateways",
        name: "Gateways",
        selector: (row) => row.gateways,
        sortable: true,
        cell: renderGateway,
      },
      {
        id: "description",
        name: "Description",
        selector: (row) => row.description,
        sortable: true,
      },
      {
        width: "40px",
        cell: renderAction,
        allowOverflow: true,
        button: true,
      },
    ];
  }, [onDelete, sn]);

  const createGatewayGroup = () => {
    history.push(`/admin/sdwan/device/${sn}/gateways/groups/create`);
  };
  return (
    <Card className="mb-0">
      <CardHeader
        className="d-flex flex-1 align-items-center py-2 border-0"
        style={{ columnGap: 4 }}
      >
        <PrimeSearchInput
          onFilter={(text) => setSmartSearch(text)}
          className="mr-4"
          fullwidth
          size="sm"
          tooltip="Press 'Enter' to search."
          tooltipOptions={{ position: "top" }}
        />
        <RefreshButton onClick={refetch} isLoading={isFetching} />
        <Button size="sm" color="primary" onClick={createGatewayGroup}>
          Add
        </Button>
      </CardHeader>
      <CardBody className="p-2">
        <DataTable
          data={data}
          defaultSortFieldId="status"
          defaultSortAsc={false}
          columns={columns}
          persistTableHead
          noHeader
          progressPending={isLoading || isFetching}
          pagination
          paginationServer
          paginationTotalRows={totalRows}
          paginationPerPage={pageSize}
          onChangePage={onChangePage}
          onChangeRowsPerPage={onChangeRowsPerPage}
          sortServer
          onSort={onSort}
          customStyles={{
            ...customStyles,
            tableWrapper: {
              style: {
                minHeight: "400px",
              },
            },
          }}
        />
      </CardBody>
    </Card>
  );
};

export default GatewayGroupTabPanel;
