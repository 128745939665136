import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody, CardTitle, Row, Col } from 'reactstrap';
import ReactECharts from 'echarts-for-react';

import { Loading } from './../../../../../components';
import { history } from 'utils';

const DeviceUsageOverQuotaCard = ({ data, loading }) => {

  const onElementClick = (params) => {
    const customerName = params.name;
    if (customerName !== null && customerName !== 'null') {
      history.push({
        pathname: "/admin/devices",
        search: `?customerName=${customerName}&connectionStatus=Connected&reachQuotaLimit=true`,
      });
    }
  }

  const onEvents = {
    'click': onElementClick
  }

  const options = useMemo(() => {
    let option = {
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow'
        }
      },
      legend: {},
      grid: {
        left: '5%',
        right: '5%',
        containLabel: true
      },
      dataZoom: [
        {
          type: 'inside',
        },
        {
          start: 0,
          end: 100
        }
      ],
      xAxis: [
        {
          type: 'time',
          boundaryGap: false,
          axisLabel: {
            formatter: {
              year: '{yyyy}',
              month: '{MMM} {yyyy}',
              day: '{d} {MMM}',
              hour: '{HH}:{mm}',
              minute: '{HH}:{mm}',
              second: '{HH}:{mm}:{ss}',
              millisecond: '{hh}:{mm}:{ss} {SSS}',
              none: '{yyyy}-{MM}-{dd} {hh}:{mm}:{ss} {SSS}'
            }
          }
        }
      ],
      yAxis: [
        {
          type: 'value',
          name: 'Total Device',
          nameLocation: 'middle',
          nameRotate: '90',
          nameGap: 35
        }
      ],
      series: []
    };

    if (data && data.datasets) {
      const labels = data.labels || [];
      const series = [];

      data.datasets.forEach(({ data: dataValue, label }) => {
        const dataSeries = [];
        dataValue.forEach((d, i) => {
          dataSeries.push({ value: [labels[i] || '', d], name: label })
        })
        series.push({
          name: label,
          type: 'bar',
          stack: 'cs',
          emphasis: {
            focus: 'series'
          },
          data: dataSeries
        })
      });

      option.series = series;
    }

    return option;
  }, [data]);

  return (
    <Card className="card-stats" style={{ height: "650px" }}>
      <CardBody>
        <Row>
          <Col>
            <CardTitle tag="h3" className="text-muted">
              OVER QUOTA USAGE
            </CardTitle>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="chart chart-lg">
              {loading ? (
                <div className="d-flex justify-content-center align-items-center h-100">
                  <Loading size="lg" />
                </div>
              ) : (
                <div className="w-100 h-100">
                  <ReactECharts option={options} style={{ width: '100%', height: '100%' }} onEvents={onEvents} />
                </div>
              )}
            </div>
          </Col>
        </Row>
      </CardBody>
    </Card>
  )
}

DeviceUsageOverQuotaCard.propTypes = {
  data: PropTypes.shape({
    labels: PropTypes.array,
    datasets: PropTypes.arrayOf(PropTypes.object)
  })
}

DeviceUsageOverQuotaCard.defaultProps = {
  data: {
    labels: [Date.now()],
    datasets: [{ label: "No Data", data: [0] }]
  }
}


export default DeviceUsageOverQuotaCard;
