import { useCallback } from "react";
import { useUpdateDeviceFirewallAliasMutation } from "../services";

export function useUpdateFirewallAlias(sn, uuid) {
  const [mutate, result] = useUpdateDeviceFirewallAliasMutation();

  const bodyMap = (data) => {
    const valueToSend = {
        alias: {
          ...data
        },
      }

    return valueToSend;
  };

  const update = useCallback(
    async (data) => {
      const body = bodyMap(data);

      if (sn) {
        await mutate({ sn, uuid , ...body }).unwrap();
      }
    },
    [mutate, sn, uuid]
  );

  return {
    update,
    isLoadingMutation: result.isLoading,
    ...result
  };
}
