import { useCallback } from "react";
import { useCreateDeviceGatewayGroupMutation } from "../services";

export function useCreateGatewayGroup(sn) {
  const [mutate, result] = useCreateDeviceGatewayGroupMutation();

  // const bodyMap = (data) => {
  //   data.priorities.forEach((priority) => {
  //     delete priority.description;
  //   });

  //   return data;
  // };

  const createGatewayGroup = useCallback(
    async (data) => {
      // const body = bodyMap(data);

      if (sn) {
        await mutate({ sn, ...data }).unwrap();
      }
    },
    [mutate, sn]
  );

  return {
    createGatewayGroup,
    ...result,
  };
}
