import { PageContent } from "components";
import React from "react";
import PageToolbar from "../component/PageToolbar";
import { useSdwanReference } from "../hooks/useSdwanReference";
import { toast } from "react-toastify";
import { history } from "utils";
import { useParams } from "react-router";
import FirewallAliasForm from "../component/Form/FirewallAliasForm";
import { useCreateFirewallAlias } from "../hooks/useCreateFirewallAlias";

const CreateFirewallAliasConfiguration = () => {
  const { sn } = useParams();
  const { options: typeOptions } = useSdwanReference("fw-alias-type");
  const { createFirewallAlias, isLoadingMutation } = useCreateFirewallAlias(sn);

  const onCreateFwAlias = async (values) => {
    try {
          await createFirewallAlias(values);
          toast.success("Command to create Firewall Alias sent.");
          history.goBack();
        } catch (err) {
          toast.error(err.data.message || "Failed to create Firewall Alias.");
        }
  };


  return (
    <PageContent style={{ marginTop: "-14rem" }}>
      <PageToolbar title="Create Firewall Alias" className="mb-3" />
      <FirewallAliasForm
        typeOptions={typeOptions}
        onSubmit={onCreateFwAlias}
        isLoading={isLoadingMutation}
      />
    </PageContent>
  );
};

export default CreateFirewallAliasConfiguration;
