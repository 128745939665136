import React, { useMemo, useState } from "react";
import DataTable from "react-data-table-component";
import {
  Button,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import OnlineStatus from "./OnlineStatus";
import { PrimeSearchInput } from "components";
import { RefreshButton } from "components/atoms/RefreshButton";
import { customStyles } from "utils/datatableStyles";
import { history } from "utils";
import { useParams } from "react-router";
import { useDeviceFirewallAliasDatatable } from "../hooks/useDeviceFirewallAliasDatatable";
import FirewallAliasFilter from "./Filter/FirewallAliasFilter";
import { useFirewallAliasFilter } from "../hooks/useFirewallAliasFilter";
import moment from "moment";
import { useDeleteFirewallAlias } from "../hooks/useDeleteFirewallAlias";

const FirewallAliasesTabPanel = () => {
  const { sn } = useParams();
  const deleteFirewallAlias = useDeleteFirewallAlias(sn);
  const [smartSearch, setSmartSearch] = useState("");
  const { filters } = useFirewallAliasFilter();
  const dt = useDeviceFirewallAliasDatatable({
    sn,
    smartSearch: smartSearch && smartSearch !== "" ? smartSearch : undefined,
    type: filters.fw_type ?? undefined,
  });

  const ContentCell = ({ content }) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const TAG_LIMIT = 3; // Number of tags to show before "See more"

    // Handle falsy or empty array content
    if (!content || !Array.isArray(content) || content.length === 0) {
      return <span></span>;
    }

    // Limit the visible tags based on isExpanded state
    const visibleTags = isExpanded ? content : content.slice(0, TAG_LIMIT);

    return (
      <div style={{ margin: "5px 0" }}>
        {/* Render tags */}
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            gap: "8px", // Increase spacing between tags
            marginBottom: '8px',
            marginTop: '8px'
          }}
        >
          {visibleTags.map((tag, index) => (
            <span
              key={index}
              title={tag} // Hover to show full content
              style={{
                backgroundColor: "#EDEDED",
                color: "#333",
                borderRadius: "15px",
                padding: "6px 12px", // Adjust padding for better spacing
                fontSize: "12px",
                display: "inline-block",
                whiteSpace: "nowrap",
                maxWidth: "150px", // Limit tag width
                overflow: "hidden",
                textOverflow: "ellipsis",
                cursor: "pointer",
              }}
            >
              {tag}
            </span>
          ))}
        </div>

        {/* See more / See less button */}
      {content.length > TAG_LIMIT && (
        <button
          onClick={() => setIsExpanded(!isExpanded)}
          style={{
            background: "none",
            color: "#2065AC",
            border: "none",
            cursor: "pointer",
            fontSize: "12px",
            display: "flex",
            fontWeight: 'bold',
            alignItems: "center",
            gap: "5px",
          }}
        >
          {isExpanded ? "See less" : "See more"}
          <span
            style={{
              transform: isExpanded ? "rotate(180deg)" : "rotate(0)",
              transition: "transform 0.3s",
            }}
          >
            ▼
          </span>
        </button>
      )}
      </div>
    );
  };

  const columns = useMemo(() => {
    const renderStatus = (row) => {
      return <OnlineStatus type={row.enabled ? "enabled" : "disabled"} />;
    };

    const renderAction = (data) => {
      const openConfiguration = () =>
        history.push(`/admin/sdwan/device/${sn}/firewall/aliases/edit/${data.uuid}`);

      return (
        <div className="mr-auto">
          <UncontrolledDropdown nav >
            <DropdownToggle nav disabled={data.type === 'External (advanced)'}>
            <i
              className="fas fa-ellipsis-v"
              style={{
                fontSize: 14,
                opacity: ['External (advanced)', 'Internal (automatic)'].includes(data.type) ? 0.5 : 1,
              }}
            ></i>
            </DropdownToggle>
            <DropdownMenu right>
              <DropdownItem onClick={openConfiguration}>
                <i className="mdi mdi-cog mdi-18px"></i>Edit
              </DropdownItem>
              <DropdownItem onClick={() => deleteFirewallAlias(data)} className="text-danger">
                <i className="mdi mdi-delete mdi-18px"></i>Delete
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </div>
      );
    };

    return [
      {
        id: "enabled",
        name: "Status".toLocaleUpperCase(),
        selector: (row) => row.enabled,
        sortable: true,
        sortField: "enabled",
        cell: renderStatus,
        width: "100px",
      },
      {
        id: "name",
        name: "Name".toLocaleUpperCase(),
        selector: (row) => row.name,
        sortable: true,
        sortField: "name",
      },
      {
        id: "type",
        name: "Type".toLocaleUpperCase(),
        selector: (row) => row.type,
        sortable: true,
        sortField: "type",
      },
      {
        id: "description",
        name: "description".toLocaleUpperCase(),
        selector: (row) => row.description,
        sortable: true,
        sortField: "description",
        wrap: true,
      },
      {
        id: "content",
        name: "content".toLocaleUpperCase(),
        selector: (row) => row.content,
        sortable: true,
        sortField: "content",
        cell: (row) => <ContentCell content={row.content} />,
      },
      {
        id: "currentItems",
        name: "Loaded Entries".toLocaleUpperCase(),
        selector: (row) => row.currentItems,
        sortable: true,
        sortField: "currentItems",
      },
      {
        id: "lastUpdated",
        name: "Last Updated".toLocaleUpperCase(),
        selector: (row) => row.lastUpdated,
        sortable: true,
        sortField: "lastUpdated",
        cell: ({ lastUpdated }) =>
          moment(lastUpdated).isValid()
            ? moment(lastUpdated).format("DD/MM/YYYY HH:mm:ss")
            : lastUpdated,
      },
      {
        width: "40px",
        cell: renderAction,
        allowOverflow: true,
        button: true,
      },
    ];
  }, [deleteFirewallAlias, sn]);

  const createGateway = () => {
    history.push(`/admin/sdwan/device/${sn}/firewall/aliases/create`);
  };

  return (
    <div>
      <div
        className="d-flex flex-wrap align-items-center justify-content-between pb-3 border-0 flex-md-nowrap"
        style={{ columnGap: 4 }}
      >
        <div className="d-flex justify-content-start align-items-top">
          {/* <h4 className="text-muted mb-0" style={{ paddingTop: "8px" }}></h4> */}
        </div>

        <div className="d-flex flex-wrap align-items-center">
          <PrimeSearchInput
            onFilter={(text) => setSmartSearch(text)}
            className="flex-1"
            size="sm"
            tooltip="Press 'Enter' to search."
            tooltipOptions={{ position: "top" }}
          />
          <FirewallAliasFilter sn={sn} />
          <RefreshButton onClick={dt.refetch} isLoading={dt.isFetching} />
          <Button
            size="md"
            color="primary"
            onClick={createGateway}
            style={{ borderRadius: "10px" }}
          >
            Create
          </Button>
        </div>
      </div>

      <div>
        <DataTable
          data={dt.data}
          defaultSortFieldId="statusTranslated"
          defaultSortAsc={false}
          responsive={true}
          columns={columns}
          persistTableHead
          noHeader
          progressPending={dt.isLoading || dt.isFetching}
          pagination
          paginationServer
          paginationTotalRows={dt.totalRows}
          paginationPerPage={dt.pageSize}
          onChangePage={dt.onChangePage}
          onChangeRowsPerPage={dt.onChangeRowsPerPage}
          sortServer
          onSort={dt.onSort}
          customStyles={{
            ...customStyles,
            tableWrapper: {
              style: {
                minHeight: "400px",
              },
            },
          }}
        />
      </div>
    </div>
  );
};

export default FirewallAliasesTabPanel;
