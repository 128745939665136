import { ButtonLinkIcon, PrimeMultiSelect, RowItem } from "components";
import { useFirewallAliasFilter } from "features/sdwan/hooks/useFirewallAliasFilter";
import { useFormik } from "formik";
import PropTypes from "prop-types";
import React, { useState } from "react";
import {
  Button,
  Col,
  Dropdown,
  DropdownMenu,
  DropdownToggle,
  Form,
  Nav,
  Row,
} from "reactstrap";
import { useSdwanReference } from "features/sdwan/hooks/useSdwanReference";

const FirewallAliasFilter = ({ sn }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { options: typeOptions } = useSdwanReference("fw-alias-type");
  const { filters, setFilters } = useFirewallAliasFilter();

  const {
    values,
    dirty,
    isSubmitting,
    handleReset,
    setFieldValue,
    handleSubmit,
    resetForm,
  } = useFormik({
    enableReinitialize: true,
    initialValues: {
      type: filters.fw_type ? filters.fw_type.split(',') : [],
    },
    onSubmit: (formData) => {
      const { type } = formData;
      setFilters({
        fw_type: type && type !== null && type !== "" && type.length ? type.join(',') : undefined,
      });
    },
  });

  const toggleMenu = () => {
    setIsOpen((prev) => !prev);
  };

  const onCancel = () => {
    setFilters({ fw_type: undefined });
    resetForm();
  }

  return (
    <Nav navbar>
      <Dropdown nav isOpen={isOpen} toggle={toggleMenu}>
        <DropdownToggle nav>
          <ButtonLinkIcon
            label=""
            icon="fa-filter"
            style={{ width: 50 }}
          />
        </DropdownToggle>
        <DropdownMenu right>
          <div style={{ minWidth: 400 }} className="p-3">
            <Form onSubmit={handleSubmit} onReset={handleReset}>
              <RowItem
                label="Type"
                value={
                  <PrimeMultiSelect
                  filter
                  options={typeOptions}
                  value={values.type}
                  onChange={(e) => setFieldValue("type", e.value)}
                  placeholder="Select Type"
                  style={{maxWidth: '180px'}}
                />
                }
                rightCol="col-7"
              />
              <Row className="mt-4">
                <Col className="text-center">
                  <Button
                    className="m-0"
                    block
                    color="default"
                    size="sm"
                    type="submit"
                    disabled={!dirty || isSubmitting}
                  >
                    Apply
                  </Button>
                  <Button
                    className="m-0 mt-2"
                    block
                    color="secondary"
                    size="sm"
                    onClick={onCancel}
                  >
                    Cancel
                  </Button>
                </Col>
              </Row>
            </Form>
          </div>
        </DropdownMenu>
      </Dropdown>
    </Nav>
  );
};

FirewallAliasFilter.propTypes = {
  sn: PropTypes.string.isRequired,
};
export default FirewallAliasFilter;
