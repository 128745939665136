import { useCallback } from "react";
import { useCreateDeviceFirewallAliasMutation } from "../services";

export function useCreateFirewallAlias(sn) {
  const [mutate, result] = useCreateDeviceFirewallAliasMutation();

  const bodyMap = (data) => {
    const valueToSend = {
        alias: {
          ...data
        },
      }

    return valueToSend;
  };

  const createFirewallAlias = useCallback(
    async (data) => {
      const body = bodyMap(data);

      if (sn) {
        await mutate({ sn, ...body }).unwrap();
      }
    },
    [mutate, sn]
  );

  return {
    createFirewallAlias,
    isLoadingMutation: result.isLoading,
    ...result,
  };
}
