export const customStyles = {
  head: {
    style: {
      textAlign: 'center'
    }
  },
  headRow: {
    style: {
      backgroundColor: "#F1F3F9",
    }
  },
  expanderCell: {
    style: {
      flex: '48px 48px',
      minWidth: '0',
    }
  },
  tableWrapper: {
		style: {
			minHeight: '530px'
		},
	},
}
export const customStyles2 = {
  head: {
    style: {
      textAlign: 'center'
    }
  },
  headRow: {
    style: {
      backgroundColor: "#F1F3F9"
    }
  },
  expanderCell: {
    style: {
      flex: '48px 48px',
      minWidth: '0',
    }
  },
  // tableWrapper: {
	// 	style: {
	// 		minHeight: '0'
	// 	},
	// },
}
export const customStyles3 = {
  head: {
    style: {
      textAlign: 'center',
      fontWeight: 'bold',
      fontSize: '14px'
    }
  },
  headRow: {
    style: {
      backgroundColor: "#F1F3F9",
    }
  },
  expanderCell: {
    style: {
      flex: '48px 48px',
      minWidth: '0',
    }
  },
}
export const customStyles4 = {
  head: {
    style: {
      textAlign: 'center'
    }
  },
  headRow: {
    style: {
      backgroundColor: "#F1F3F9"
    }
  },
  headCells: {
    style: {
      position: "sticky",
      top: 0, // Freeze the header row
      backgroundColor: "#F1F3F9", // Header background
      zIndex: 3,
      "&:nth-of-type(1)": {
        position: "sticky",
        left: 0,
        zIndex: 4,
        borderRight: "1px solid #E0E0E0",
      },
      "&:last-of-type": {
        position: "sticky",
        right: 0,
        zIndex: 4,
      },
    },
  },
  cells: {
    style: {
      '&:nth-of-type(1)': {
        position: 'sticky',
        left: 0,
        backgroundColor: 'inherit',
        zIndex: 2,
        borderRight: "1px solid #E0E0E0",
      },

      '&:last-of-type': {
        position: 'sticky',
        right: 0,
        backgroundColor: 'inherit',
        zIndex: 2,
      },
    },
  },
}