import React, { useMemo } from "react";
import { TritronikInputText, TritronikSelect } from "components";
import { Formik } from "formik";
import { InputSwitch } from "primereact/inputswitch";
import {
  Button,
  Card,
  CardBody,
  Col,
  Form,
  FormText,
  Row,
  Spinner,
} from "reactstrap";
import FormItem from "../FormItem";
import { history } from "utils";
import CollapsibleHelper from "../CollapsibleHelper";
import PropTypes from "prop-types";
import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Gateway Name required."),
  ipprotocol: Yup.string().required("Address Family required."),
  address: Yup.string()
    .required("IP Address required.")
    .when("ipprotocol", {
      is: "inet",
      then: Yup.string().matches(
        /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/,
        "IPv4 Address must be a valid IP format"
      ),
      otherwise: Yup.string().matches(
        /^((([0-9A-Fa-f]{1,4}:){1,6}:)|(([0-9A-Fa-f]{1,4}:){7}))([0-9A-Fa-f]{1,4})$/,
        "IPv6 Address must be a valid format"
      ),
    }),
  monitor: Yup.string().when("ipprotocol", {
    is: "inet",
    then: Yup.string().matches(
      /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/,
      "IPv4 Address must be a valid IP format"
    ),
    otherwise: Yup.string().matches(
      /^((([0-9A-Fa-f]{1,4}:){1,6}:)|(([0-9A-Fa-f]{1,4}:){7}))([0-9A-Fa-f]{1,4})$/,
      "IPv6 Address must be a valid format"
    ),
  }),
  priority: Yup.number(),
  latencylow: Yup.number(),
  latencyhigh: Yup.number(),
  losslow: Yup.number(),
  losshigh: Yup.number(),
});

const GatewayConfigurationForm = ({
  data,
  interfaceOptions = [],
  ipFamilyOptions = [],
  onSubmit,
  isLoading,
}) => {
  const initialValues = useMemo(() => {
    return {
      enabled: !data?.disabled ?? false,
      name: data?.name ?? "",
      descr: data?.descr ?? "",
      priority: data?.priority ?? "",
      interface: data?.interface ?? "",
      defeaultgw: data?.defaultgw ? data.defaultgw : false,
      monitorDisable: data?.monitorDisable ? data.monitorDisable : false,
      ipprotocol: data?.ipprotocol ?? "ipv4",
      address: data?.address ?? "",
      monitor: data?.monitor ?? "",
      latencylow: data?.latencylow ?? 200,
      latencyhigh: data?.latencyhigh ?? 500,
      losslow: data?.losslow ?? 10,
      losshigh: data?.losshigh ?? 20,
    };
  }, [data]);

  const onCancel = () => {
    history.goBack();
  };

  const _onSubmit = async (values) => {
    if (typeof onSubmit === "function") {
      const { enabled, ...formData } = values;
      const transformData = {
        disabled: !enabled,
        ...formData,
      };
      transformData.gateway = formData.address
      
      await onSubmit(transformData);
    }
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={_onSubmit}
      validationSchema={validationSchema}
    >
      {({
        values,
        touched,
        errors,
        isSubmitting,
        setFieldValue,
        handleSubmit,
      }) => {
        return (
          <div className="position-relative">
            <Form onSubmit={handleSubmit}>
              <Card className="mb-3">
                <CardBody>
                  <Row>
                    <Col md="3">
                      <h3 className="mt-md-2 font-weight-medium">
                        General Configuration
                      </h3>
                    </Col>
                    <Col md="9">
                      <FormItem label="Enable">
                        <InputSwitch
                          checked={values.enabled}
                          onChange={(e) => setFieldValue("enabled", e.value)}
                        />
                      </FormItem>
                      <FormItem label="Gateway Name" required>
                        <TritronikInputText name="name" small readOnly={Boolean(data)} />
                      </FormItem>
                      <FormItem label="Description">
                        <TritronikInputText name="descr" small />
                      </FormItem>
                      <FormItem label="Priority">
                        <TritronikInputText
                          size="sm"
                          type="number"
                          name="priority"
                          small
                          helperText="Choose a value between 1 and 255. Influences sort order when selecting a (default) gateway, lower means more important."
                        />
                      </FormItem>
                      <FormItem label="Interface">
                        <TritronikSelect
                          value={values.interface}
                          name="interface"
                          options={interfaceOptions}
                          onChange={(e) => setFieldValue("interface", e.value)}
                          invalid={touched.interface && errors.interface}
                          error={errors.interface}
                          hint="Choose which interface this gateway applies to."
                        />
                      </FormItem>
                      <FormItem label="Upstram Gateway">
                        <div className="w-100">
                          <InputSwitch
                            checked={values.defeaultgw}
                            onChange={(e) =>
                              setFieldValue("defeaultgw", e.value)
                            }
                          />
                          <FormText color="muted">
                            This will select the above gateway as a default
                            candidate.
                          </FormText>
                        </div>
                      </FormItem>
                      <FormItem label="Disable Host Route">
                        <div className="w-100">
                          <InputSwitch
                            checked={values.monitorDisable}
                            onChange={(e) =>
                              setFieldValue("monitorDisable", e.value)
                            }
                          />
                          <FormText color="muted">
                            Do not create a dedicated host route for this
                            monitor.
                          </FormText>
                        </div>
                      </FormItem>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
              <Card className="mb-3">
                <CardBody>
                  <Row>
                    <Col md="3" sm="12">
                      <h3 className="mt-md-2 font-weight-medium">
                        IP Configuration
                      </h3>
                    </Col>
                    <Col md="9" sm="12">
                      <FormItem label="Address Family" required>
                        <TritronikSelect
                          value={values.ipprotocol}
                          name="ipprotocol"
                          options={ipFamilyOptions}
                          onChange={(e) => setFieldValue("ipprotocol", e.value)}
                          invalid={touched.ipprotocol && errors.ipprotocol}
                          error={errors.ipprotocol}
                          hint="Choose the Internet Protocol this gateway uses."
                        />
                      </FormItem>
                      <FormItem label="IP Address" required>
                        <TritronikInputText name="address" small />
                      </FormItem>
                      <FormItem label="Monitor IP">
                        <div className="w-100">
                          <TritronikInputText name="monitor" small />
                          <CollapsibleHelper text="Enter an alternative address here to be used to monitor the link.">
                            <FormText color="muted">
                              This is used for the quality RRD graphs as well as
                              the load balancer entries. Use this if the gateway
                              does not respond to ICMP echo requests (pings).
                            </FormText>
                          </CollapsibleHelper>
                        </div>
                      </FormItem>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
              <Card style={{ marginBottom: "7rem" }}>
                <CardBody>
                  <Row>
                    <Col md="3" sm="12">
                      <h3 className="mt-md-2 font-weight-medium">Threshold</h3>
                    </Col>
                    <Col md="9" sm="12">
                      <FormItem label="Latency Low Threshold">
                        <div className="w-100 mb-2">
                          <div className="p-inputgroup">
                            <TritronikInputText small name="latencylow" />
                            <span className="p-inputgroup-addon small">ms</span>
                          </div>
                          <FormText color="muted">
                            Low threshold for latency in milliseconds. Default
                            is 200.
                          </FormText>
                        </div>
                      </FormItem>
                      <FormItem label="Latency High Threshold">
                        <div className="w-100 mb-2">
                          <div className="p-inputgroup">
                            <TritronikInputText small name="latencyhigh" />
                            <span className="p-inputgroup-addon small">ms</span>
                          </div>
                          <FormText color="muted">
                            High threshold for latency in milliseconds. Default
                            is 500.
                          </FormText>
                        </div>
                      </FormItem>
                      <FormItem label="Packet Loss Low Threshold">
                        <div className="w-100 mb-2">
                          <div className="p-inputgroup">
                            <TritronikInputText small name="losslow" />
                            <span className="p-inputgroup-addon small">%</span>
                          </div>
                          <FormText color="muted">
                            Low threshold for packet loss in %. Default is 10.
                          </FormText>
                        </div>
                      </FormItem>
                      <FormItem label="Packet Loss High Threshold">
                        <div className="w-100 mb-2">
                          <div className="p-inputgroup">
                            <TritronikInputText small name="losshigh" />
                            <span className="p-inputgroup-addon small">%</span>
                          </div>
                          <FormText color="muted">
                            High threshold for packet loss in %. Default is 20.
                          </FormText>
                        </div>
                      </FormItem>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
              <Card
                className="mb-0 position-fixed rounded-0"
                style={{ padding: "0px 30px", bottom: 0, left: 0, right: 0 }}
              >
                <CardBody
                  className="d-flex justify-content-end"
                  style={{ gap: 8 }}
                >
                  <Button
                    color="secondary"
                    size="sm"
                    className="px-5"
                    onClick={onCancel}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    color="primary"
                    size="sm"
                    className="px-5"
                    disabled={isSubmitting || isLoading}
                  >
                    Save
                    {isLoading && (
                      <Spinner className="ml-2" color="light" size="sm" />
                    )}
                  </Button>
                </CardBody>
              </Card>
            </Form>
          </div>
        );
      }}
    </Formik>
  );
};

GatewayConfigurationForm.propTypes = {
  interfaceOptions: PropTypes.array,
  ipFamilyOptions: PropTypes.array,
  data: PropTypes.any,
  onSubmit: PropTypes.func,
  isLoading: PropTypes.bool,
};

export default GatewayConfigurationForm;
