import { createApi } from "@reduxjs/toolkit/query/react";

import { baseQuery } from "./../../../services/baseApi";

export const sdwanApi = createApi({
  reducerPath: "sdwanApi",
  baseQuery: baseQuery,
  tagTypes: ["Device", "Interface", "Gateway", "GatewayGroup", "Reference", "Alias"],
  endpoints: (builder) => ({
    getDevices: builder.query({
      query: (params) => ({
        url: `/device`,
        params: params,
      }),
      providesTags: (result) =>
        result?.content
          ? [
              ...result.content.map(({ id }) => ({ type: "Device", id })),
              { type: "Device", id: "LIST" },
            ]
          : [{ type: "Device", id: "LIST" }],
    }),
    getDevice: builder.query({
      query: (sn) => ({
        url: `/device/${sn}/detail`,
      }),
      providesTags: (result, error, id) => [{ type: "Device", id }],
    }),
    getDeviceInterfaces: builder.query({
      query: ({ sn, ...params }) => ({
        url: `/device/${sn}/network-interfaces`,
        params,
      }),
      providesTags: (result) =>
        result?.content
          ? [
              ...result.content.map(({ id }) => ({ type: "Interface", id })),
              { type: "Interface", id: "LIST" },
            ]
          : [{ type: "Interface", id: "LIST" }],
    }),
    getDeviceGateways: builder.query({
      query: ({ sn, ...params }) => ({
        url: `/device/${sn}/network-gateways`,
        params,
      }),
      providesTags: (result) =>
        result?.content
          ? [
              ...result.content.map(({ id }) => ({ type: "Gateway", id })),
              { type: "Gateway", id: "LIST" },
            ]
          : [{ type: "Gateway", id: "LIST" }],
    }),
    createDeviceGateway: builder.mutation({
      query: ({ sn, ...body }) => ({
        url: `/device/${sn}/network-gateways`,
        method: "POST",
        body,
      }),
      invalidatesTags: [{ type: "Gateway", id: "LIST" }],
    }),
    updateDeviceGateway: builder.mutation({
      query: ({ sn, uuid, ...body }) => ({
        url: `/device/${sn}/network-gateways/${uuid}`,
        method: "PUT",
        ...body,
      }),
      invalidatesTags: ["Gateway"],
    }),
    deleteDeviceGateway: builder.mutation({
      query: ({ sn, uuid }) => ({
        url: `/device/${sn}/network-gateways/${uuid}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Gateway"],
    }),
    getDeviceGatewayGroups: builder.query({
      query: ({ sn, ...params }) => ({
        url: `/device/${sn}/network-gateway-groups`,
        params,
      }),
      providesTags: (result) =>
        result?.content
          ? [
              ...result.content.map(({ id }) => ({ type: "GatewayGroup", id })),
              { type: "GatewayGroup", id: "LIST" },
            ]
          : [{ type: "GatewayGroup", id: "LIST" }],
    }),
    createDeviceGatewayGroup: builder.mutation({
      query: ({ sn, ...body }) => ({
        url: `/device/${sn}/network-gateway-group`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["GatewayGroup"],
    }),
    updateDeviceGatewayGroup: builder.mutation({
      query: ({ sn, gatewayGroupIdx, ...body }) => ({
        url: `/device/${sn}/network-gateway-group/${gatewayGroupIdx}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["GatewayGroup"],
    }),
    deleteDeviceGatewayGroup: builder.mutation({
      query: ({ sn, id }) => ({
        url: `/device/${sn}/network-gateway-group/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["GatewayGroup"],
    }),
    getDeviceInterfaceById: builder.query({
      query: (id) => ({
        url: `/network-interfaces/${id}`,
      }),
      providesTags: (result, error, id) => [{ type: "Interface", id }],
    }),
    updateNetworkInterfaceById: builder.mutation({
      query: ({ id, ...body }) => ({
        url: `/network-interfaces/${id}`,
        method: "PUT",
        ...body,
      }),
      invalidatesTags: ["Interface"],
    }),
    getDeviceGatewayById: builder.query({
      query: (id) => ({
        url: `/network-gateways/${id}`,
      }),
      providesTags: (result, error, id) => [{ type: "Gateway", id }],
    }),
    getDeviceGatewayGroupById: builder.query({
      query: (id) => ({
        url: `/network-gateway-groups/${id}`,
      }),
      providesTags: (result, error, id) => [{ type: "GatewayGroup", id }],
      keepUnusedDataFor: 0,
      refetchOnMountOrArgChange: true, 
    }),
    getDeviceFirewallAliasById: builder.query({
      query: ({ sn, uuid }) => ({
        url: `/device/${sn}/firewalls/alias/${uuid}`,
      }),
      providesTags: (result, error, id) => [{ type: "Alias", id }],
      keepUnusedDataFor: 0,
      refetchOnMountOrArgChange: true, 
    }),
    useGetSdwanReference: builder.query({
      query: (name) => ({
        url: `/reference/sd-wan/${name}`,
      }),
      providesTags: (result, error, id) => [{ type: "Reference", id }],
    }),
    getDeviceFirewallAlias: builder.query({
      query: ({ sn, ...params }) => ({
        url: `/device/${sn}/firewalls/alias`,
        params,
      }),
      providesTags: (result) =>
        result?.content
          ? [
              ...result.content.map(({ id }) => ({ type: "Alias", id })),
              { type: "Alias", id: "LIST" },
            ]
          : [{ type: "Alias", id: "LIST" }],
    }),
    deleteDeviceFirewallAlias: builder.mutation({
      query: ({ sn, uuid }) => ({
        url: `/device/${sn}/firewalls/alias`,
        method: "DELETE",
        params: {uuid}
      }),
      invalidatesTags: ["Alias"],
    }),
    createDeviceFirewallAlias: builder.mutation({
      query: ({ sn, ...body }) => ({
        url: `/device/${sn}/firewalls/alias`,
        method: "POST",
        body,
      }),
      invalidatesTags: [{ type: "Alias", id: "LIST" }],
    }),
    updateDeviceFirewallAlias: builder.mutation({
      query: ({ sn, uuid, ...body }) => ({
        url: `/device/${sn}/firewalls/alias`,
        method: "PUT",
        params: {uuid},
        body,
      }),
      invalidatesTags: ["Alias"],
    }),
  }),
});

export const {
  useGetDeviceQuery,
  useGetDevicesQuery,
  useCreateDeviceGatewayGroupMutation,
  useUpdateDeviceGatewayGroupMutation,
  useCreateDeviceGatewayMutation,
  useUpdateDeviceGatewayMutation,
  useDeleteDeviceGatewayGroupMutation,
  useDeleteDeviceGatewayMutation,
  useGetDeviceGatewayGroupsQuery,
  useGetDeviceGatewaysQuery,
  useGetDeviceInterfacesQuery,
  useGetDeviceGatewayByIdQuery,
  useGetDeviceGatewayGroupByIdQuery,
  useGetDeviceInterfaceByIdQuery,
  useUseGetSdwanReferenceQuery,
  useUpdateNetworkInterfaceByIdMutation,
  useGetDeviceFirewallAliasQuery,
  useDeleteDeviceFirewallAliasMutation,
  useUpdateDeviceFirewallAliasMutation,
  useCreateDeviceFirewallAliasMutation,
  useGetDeviceFirewallAliasByIdQuery,
} = sdwanApi;
