import React from "react";
import { toast } from "react-toastify";
import { Modal } from "reactstrap";
import { useUpdateRouterMutation } from "../service";
import FormRouter from "./FormRouter";

const EditRouterModal = ({ isOpen, toggle, router }) => {
  const [updateRouter, { isLoading }] = useUpdateRouterMutation();

  const onSubmit = (body) => {
    const valueToSend = {
        ...body,
        id: router.id,
        updateModified: null,
    }

    updateRouter({ id: router.id, body:valueToSend })
      .unwrap()
      .then(() => {
        toast.success(`Router ${router.device} updated.`);
        toggle();
      });
  };

  return (
    <Modal
      className="modal-dialog-centered"
      size="md"
      isOpen={isOpen}
      toggle={toggle}
      backdrop={isLoading}
    >
      <FormRouter
        router={router}
        onSubmit={onSubmit}
        onCancel={toggle}
        isLoading={isLoading}
      />
    </Modal>
  );
};

export default EditRouterModal;
