import { PageContent, CustomExpandableIcon } from "../../../components";
import React from "react";
import DataTable from "react-data-table-component";
import { Card, CardBody, Col, Row } from "reactstrap";
import { customStyles } from "../../../utils/datatableStyles";
import { useGetOnuQuery } from "../../inventory/onu/service";
import { RegisteredOnuTableDetail } from "../components/registeredOnuTableDetail";

const RegisteredOnuList = () => {
  const { data: onu, isLoading } = useGetOnuQuery();

  const columns = [
    {
      name: "ONU ID",
      center: true,
      hide: "md",
      selector: (row) => row?.id,
    },
    {
      name: "SERIAL NUMBER",
      center: true,
      hide: "md",
      selector: (row) => row?.sn,
    },
    {
      name: "CONNECTION STATUS",
      center: true,
      hide: "md",
      // selector: (row) => row?.registerStatus,
    },
    // {
    //   name: "PASSWORD",
    //   center: true,
    //   hide: "md",
    //   cell: () => <div>***********</div>,
    // },
    {
      name: "OLT ID",
      center: true,
      hide: "md",
      selector: (row) => row?.oltId,
    },
    {
      name: "SLOT ID",
      center: true,
      hide: "md",
      selector: (row) => row?.slotId,
    },
  ];

  const ExpandedComponent = (props) => {
    return (
      <RegisteredOnuTableDetail {...props}/>
    )
  }

  return (
    <PageContent title="Optical Network Terminal">
      <Card>
        <CardBody>
          <Row className="mb-2">
            <Col xs="12" md="6">
              <h2 className="text-dark">ONU/ ONT</h2>
            </Col>
          </Row>
          <Row>
            <Col>
              <DataTable
                data={onu?.content}
                columns={columns}
                progressPending={isLoading}
                persistTableHead
                highlightOnHover
                noHeader
                striped
                pagination
                customStyles={customStyles}
                expandableRows
                expandableRowsComponent={ExpandedComponent}
                expandableIcon={{
                    collapsed: <CustomExpandableIcon type="collapsed" />,
                    expanded: <CustomExpandableIcon type="expanded" />,
                  }}
              />
            </Col>
          </Row>
        </CardBody>
      </Card>
    </PageContent>
  );
};

export default RegisteredOnuList;
