import React from 'react'
import { Col, Row } from 'reactstrap'
import { ChartJS, Loading, SLACard } from './../../../components'

const SignalReportContent = ({ loading, pieChartData, panelData }) => {
  return (
    <Row>
      <Col className="col-lg-4 col-sm-12">
        <div className="chart mb-2"  id="signalReportContent">
          {loading && (
            <div className="d-flex justify-content-center align-items-center h-100">
              <Loading size="lg" />
            </div>
          )}
          {!loading && (
            <ChartJS
              type="pie"
              data={pieChartData}
              options={{
                responsive: true,
                maintainAspectRatio: false,
                legend: {
                  display: true,
                  position: 'bottom',
                  labels: {
                    usePointStyle: false,
                  },
                  onClick: null,
                },
                pieceLabel: {
                  render: "percentage",
                  fontSize: 16,
                  fontColor: "#fff",
                  fontStyle: "normal",
                },
              }}
            />
          )}
        </div>
      </Col>
      <Col className="col-lg-8 col-sm-12">
        {loading && (
          <div className="d-flex justify-content-center align-items-center h-100">
            <Loading size="lg" />
          </div>
        )}
        {!loading && (
          <Row>
            {panelData && panelData.map((panel, i) => {
              const { label, value, unit, deviation } = panel;
              return (
                <Col lg="4" sm="6" className="my-3" key={`signal-panel-${i}`}>
                  <SLACard
                    title={label}
                    value={value}
                    unitValue={unit}
                    status={deviation !== null? deviation?.status: undefined}
                    statusText={deviation !== null ? `${deviation?.value}${deviation?.unit} than ${deviation?.comparison}` : undefined}
                    reverseStatus
                  />
                </Col>
              )
            })}
          </Row>
        )}
      </Col>
    </Row>
  )
}

export default SignalReportContent
