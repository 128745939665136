import React from "react";
import { toast } from "react-toastify";
import { Modal } from "reactstrap";
import { useCreatePackagePolicyMutation } from "../service/packagePolicyApi";
import FormPackagePolicy from "./FormPackagePolicy";

const AddPackagePolicyModal = ({ isOpen, toggle }) => {
  const [createPackagePolicy, { isLoading }] = useCreatePackagePolicyMutation();

  const onSubmit = (body) => {
    const valueToSend = {
      ...body,
      updateModified: null,
    };

    createPackagePolicy(valueToSend)
      .unwrap()
      .then(() => {
        toast.success(`Policy ${body.packageName} updated.`);
        toggle();
      });
  };

  return (
    <Modal className="modal-dialog-centered" size="md" isOpen={isOpen} backdrop={isLoading}>
      <FormPackagePolicy onSubmit={onSubmit} onCancel={toggle} isLoading={isLoading}/>
    </Modal>
  );
};

export default AddPackagePolicyModal;
