import React, { useCallback, useMemo } from "react";
import { TritronikInputText, TritronikSelect } from "components";
import { Formik } from "formik";
import { Button, Card, CardBody, Col, FormText, Row, Table } from "reactstrap";
import FormItem from "./../FormItem";
import CollapsibleHelper from "./../CollapsibleHelper";
import { history } from "utils";
import PropTypes from "prop-types";
import * as Yup from "yup";

const tierOptions = [
  { label: 'Tier 1', value: 1 },
  { label: 'Tier 2', value: 2 },
  { label: 'Tier 3', value: 3 },
  { label: 'Tier 4', value: 4 },
  { label: 'Tier 5', value: 5 },
  { label: 'Never', value: 0 }
];

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Gateway Group Name is required"),
});

const GatewayGroupForm = ({ data, gatewaListOptions, poolOptions, triggerLevelOptions, onSubmit }) => {
  
  const optionsTemplate = (option) => (
    <div className="d-flex flex-column">
      <div>{option.label}</div>
      {option.hint ? (
        <FormText className="text-wrap" color="muted">
          {option.hint}
        </FormText>
      ) : null}
    </div>
  );

  const sortByPriority = (data) => {
    return [...data].sort((a, b) => {
      if (a.priority === 0 && b.priority !== 0) return 1; // `a` with priority 0 goes after `b`
      if (b.priority === 0 && a.priority !== 0) return -1; // `b` with priority 0 goes after `a`
      return a.priority - b.priority; // Regular ascending sort
    });
  }

  const defaultPriorities = useCallback(() => {
    if (gatewaListOptions && gatewaListOptions.length > 0) {
      return gatewaListOptions.map(gw => ({
        name: gw.value,
        priority: 0,
        description: gw.description ?? '',
      }));
    } else {
      return [];
    }
  }, [gatewaListOptions]);
  
  const initialValues = useMemo(() => ({
    name: data?.name ?? "",
    description: data?.description ?? "",
    pool_options: data?.pool_options ?? "",
    trigger_level: data?.trigger_level ?? "down",
    priorities: data?.priorities ? sortByPriority(data.priorities) : defaultPriorities()
  }), [data, defaultPriorities]);

  
  return (
    <Formik enableReinitialize initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
      {({ values, touched, errors, setFieldValue, handleSubmit }) => (
        <div className="position-relative">
          <Card className="mb-3">
            <CardBody>
              <Row>
                <Col md="3">
                  <h3 className="mt-md-2 font-weight-medium">
                    General Configuration
                  </h3>
                </Col>
                <Col md="9">
                  <FormItem label="Name" required>
                    <TritronikInputText name="name" small/>
                  </FormItem>
                  <FormItem label="Description">
                    <TritronikInputText name="description" small />
                  </FormItem>
                  <FormItem label="Trigger Level">
                    <TritronikSelect
                      name="trigger_level"
                      value={values.trigger_level}
                      options={triggerLevelOptions}
                      onChange={(e) => setFieldValue("trigger_level", e.value)}
                      invalid={touched.trigger_level && errors.trigger_level}
                      error={errors.trigger_level}
                      hint="When to trigger exclusion of a gateway"
                    />
                  </FormItem>
                  <FormItem label="Pool Options">
                      <div className="w-100">
                        <TritronikSelect
                          value={values.pool_options}
                          name="pool_options"
                          options={poolOptions}
                          onChange={(e) => setFieldValue("pool_options", e.value)}
                          invalid={touched.pool_options && errors.pool_options}
                          error={errors.pool_options}
                          itemTemplate={optionsTemplate}
                        />
                        <CollapsibleHelper text="Default: Round Robin, Sticky Address determined by advanced settings">
                          <FormText color="muted">
                            Round Robin: Loops through the translation
                            addresses. Sticky Address: The Sticky Address option
                            can be used with the Round Robin pool type to ensure
                            that a particular source address is always mapped to
                            the same translation address.
                          </FormText>
                        </CollapsibleHelper>
                      </div>
                    </FormItem>
                </Col>
              </Row>
            </CardBody>
          </Card>

          {/* Priority Configuration Section */}
          <Card style={{ marginBottom: "7rem" }}>
            <CardBody>
              <Row>
                <Col md="3" sm="12">
                  <h3 className="mt-md-2 font-weight-medium">
                    Priority Configuration
                  </h3>
                  <FormText color="muted">
                    Priority determines failover and load balancing order. Links with the same priority balance traffic until all are exhausted, then the next level is used.
                  </FormText>
                </Col>
                <Col md="9" sm="12">
                  <Table responsive>
                    <thead>
                      <tr style={{backgroundColor: '#F8F8FA'}}>
                        <th style={{fontWeight: 'bold', fontSize: '13px', textTransform: 'none'}}>Gateway</th>
                        <th style={{fontWeight: 'bold', fontSize: '13px', textTransform: 'none'}}>Description</th>
                        <th style={{fontWeight: 'bold', fontSize: '13px', textTransform: 'none'}}>Tier</th>
                      </tr>
                    </thead>
                    <tbody>
                      {values.priorities && values.priorities.length > 0 ? (
                        values.priorities.map((tier, id) => (
                          <tr key={id}>
                            <td style={{verticalAlign: 'middle'}}>{tier.name}</td>
                            <td style={{verticalAlign: 'middle'}}>{tier.description}</td>
                            <td style={{verticalAlign: 'middle'}}>
                              <TritronikSelect
                                name={`priorities[${id}].priority`}
                                value={values.priorities[id].priority}
                                options={tierOptions}
                                onChange={(e) => setFieldValue(`priorities[${id}].priority`, e.value)}
                                invalid={touched[`priorities[${id}].priority`] && errors[`priorities[${id}].priority`]}
                                error={errors[`priorities[${id}].priority`]}
                                itemTemplate={optionsTemplate}
                                placeholder="Select Priority"
                              />
                              {/* <TritronikSelect
                                name={`tier_${id + 1}`}
                                value={values[`tier_${id + 1}`]}
                                options={gatewaListOptions}
                                onChange={(e) => setFieldValue(`tier_${id + 1}`, e.value)}
                                invalid={touched[`tier_${id + 1}`] && errors[`tier_${id + 1}`]}
                                error={errors[`tier_${id + 1}`]}
                                itemTemplate={optionsTemplate}
                                placeholder="Select Priority"
                              /> */}
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="3">Something went wrong</td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                </Col>
              </Row>
            </CardBody>
          </Card>

          {/* Save and Cancel Buttons */}
          <Card className="mb-0 position-fixed rounded-0" style={{ padding: "0px 30px", bottom: 0, left: 0, right: 0 }}>
            <CardBody className="d-flex justify-content-end" style={{ gap: 8 }}>
              <Button color="secondary" size="sm" className="px-5" onClick={history.goBack}>
                Cancel
              </Button>
              <Button color="primary" size="sm" className="px-5" onClick={handleSubmit}>
                Save
              </Button>
            </CardBody>
          </Card>
        </div>
      )}
    </Formik>
  );
};

GatewayGroupForm.propTypes = {
  data: PropTypes.object,
  gatewaListOptions: PropTypes.array,
  poolOptions: PropTypes.array.isRequired,
  triggerLevelOptions: PropTypes.array.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default GatewayGroupForm;
