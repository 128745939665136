import { useCallback } from "react";
import { useUpdateDeviceGatewayGroupMutation } from "../services";
import { useParams } from "react-router";

export function useUpdateGatewayGroup(sn) {
    const { gatewayGroupIdx } = useParams();
  const [mutate, result] = useUpdateDeviceGatewayGroupMutation();

  const updateGatewayGroup = useCallback(
    async (data) => {
      if (sn) {
        await mutate({ sn, gatewayGroupIdx, ...data }).unwrap();
      }
    },
    [gatewayGroupIdx, mutate, sn]
  );

  return {
    updateGatewayGroup,
    ...result,
  };
}
