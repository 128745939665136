import React, { useMemo, useState } from "react";
import DataTable from "react-data-table-component";
import {
  Badge,
  Button,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
// import OnlineStatus from "./OnlineStatus";
import { PrimeSearchInput } from "components";
import { RefreshButton } from "components/atoms/RefreshButton";
import { customStyles4 } from "utils/datatableStyles";
import { history } from "utils";
import { useDeleteGateway } from "../hooks/useDeleteGateway";
import { useParams } from "react-router";
import DeviceGatewayFilter from "./Filter/DeviceGatewayFilter";
import { useDeviceGatewayFilter } from "../hooks/useDeviceGatewayFilter";
import { useDeviceFirewallAliasDatatable } from "../hooks/useDeviceFirewallAliasDatatable";
import classNames from "classnames";
import OnlineStatus from "./OnlineStatus";

const dummyData = [
  {
    "uuid": "e9804c4e-5db0-414b-b071-071abd487810",
    "enabled": true,
    "action": "pass",
    "interfaces": [
      "wan"
    ],
    "direction": "in",
    "tcpIp": "ipv4",
    "protocol": "*",
    "gateway": "*",
    "src": {
      "host": "*",
      "port": "*"
    },
    "invertSrc": false,
    "dst": {
      "host": "remote_dm",
      "port": "*"
    },
    "invertDst": false,
    "description": null,
    "enableLog": false
  },
  {
    "uuid": "e892c6cf-b02e-49d4-9c02-8c7e9fb394b3",
    "enabled": false,
    "action": "block",
    "interfaces": [
      "wan"
    ],
    "direction": "in",
    "tcpIp": "ipv4",
    "protocol": "tcp/udp",
    "gateway": "*",
    "src": {
      "host": "*",
      "port": "*"
    },
    "invertSrc": false,
    "dst": {
      "host": "wanip",
      "port": "4500"
    },
    "invertDst": false,
    "description": null,
    "enableLog": false
  },
  {
    "uuid": "fc8c94b1-2159-4796-8f7d-09d96eab5529",
    "enabled": true,
    "action": "reject",
    "interfaces": [
      "wan"
    ],
    "direction": "in",
    "tcpIp": "ipv4",
    "protocol": "tcp/udp",
    "gateway": "*",
    "src": {
      "host": "*",
      "port": "*"
    },
    "invertSrc": false,
    "dst": {
      "host": "wanip",
      "port": "500"
    },
    "invertDst": false,
    "description": null,
    "enableLog": false
  },
]

const FirewallRulesTabPanel = () => {
  const { sn } = useParams();
  const deleteGateway = useDeleteGateway(sn);
  const [smartSearch, setSmartSearch] = useState("");
  const [selectedRuleCreatedBy, setSelectedRuleCreatedBy] = useState("User");
  const { filters } = useDeviceGatewayFilter();
  const dt = useDeviceFirewallAliasDatatable({
    sn,
    smartSearch: smartSearch && smartSearch !== "" ? smartSearch : undefined,
    name: filters.gw_name ?? undefined,
    status: filters.gw_status ?? undefined,
    created_by: selectedRuleCreatedBy.toLocaleLowerCase(),
  });

  const columns = useMemo(() => {
    const renderActionStatus = (row) => {
      let icon;
      let color;

      switch (row.action) {
        case "pass":
          icon = "fas fa-check-circle";
          color = "#2DCE89";
          break;
        case "block":
          icon = "fas fa-times-circle";
          color = "#F5365C";
          break;
        case "reject":
          icon = "fas fa-ban";
          color = "#F5365C";
          break;
        default:
          icon = "ℹ️";
          color = "gray";
      }
      return (
        <Badge className="badge-dot mr-1">
          <div 
          style={{
            display: "flex",
            alignItems: "center",
            margin: "5px 0",
          }}
          >
            <i className={classNames(icon)} style={{ fontSize: 14, color }}/>
            <span className="text-dark" style={{marginTop: '5px', marginLeft: '10px'}}>{`${row.action.charAt(0).toUpperCase() + row.action.slice(1)} from ${row.src.host} to ${row.dst.host}`}</span>
          </div>
        </Badge>
      );
    };

    const renderStatus = (row) => {
          return <OnlineStatus type={row.enabled ? "enabled" : "disabled"} />;
        };

    const renderAction = (data) => {
      const openConfiguration = () =>
        history.push(
          `/admin/sdwan/device/${sn}/gateways/configurations/configuration/${data.id}`
        );

      return (
        <div className="mr-auto">
          <UncontrolledDropdown nav>
            <DropdownToggle nav>
              <i className="fas fa-ellipsis-v" style={{ fontSize: 14 }}></i>
            </DropdownToggle>
            <DropdownMenu right>
              <DropdownItem onClick={openConfiguration} disabled>
                Edit
              </DropdownItem>
              <DropdownItem onClick={() => deleteGateway(data)} disabled>
                Delete
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </div>
      );
    };

    return [
      {
        id: "action",
        name: "action".toLocaleUpperCase(),
        selector: (row) => row?.action,
        sortable: true,
        sortField: "action",
        cell: renderActionStatus,
        width: "250px",
      },
      {
        id: "enabled",
        name: "status".toLocaleUpperCase(),
        selector: (row) => renderStatus(row),
        sortable: true,
        sortField: "enabled",
      },
      {
        id: "description",
        name: "description".toLocaleUpperCase(),
        selector: (row) => row.type,
        sortable: true,
        sortField: "description",
      },
      {
        id: "interfaces",
        name: "interfaces".toLocaleUpperCase(),
        selector: (row) => row.description,
        sortable: true,
        sortField: "description",
      },
      {
        id: "tcp",
        name: "tcp/ip version".toLocaleUpperCase(),
        selector: (row) => row.content,
        sortable: true,
        sortField: "content",
      },
      {
        id: "protocol",
        name: "protocol".toLocaleUpperCase(),
        selector: (row) => row.currentItems,
        sortable: true,
        sortField: "currentItems",
      },
      {
        id: "source",
        name: "source".toLocaleUpperCase(),
        selector: (row) => row.lastUpdated,
        sortable: true,
        sortField: "lastUpdated",
      },
      {
        id: "port",
        name: "port".toLocaleUpperCase(),
        selector: (row) => row.lastUpdated,
        sortable: true,
        sortField: "lastUpdated",
      },
      {
        id: "destination",
        name: "destination".toLocaleUpperCase(),
        selector: (row) => row.lastUpdated,
        sortable: true,
        sortField: "lastUpdated",
      },
      {
        id: "gateway",
        name: "gateway".toLocaleUpperCase(),
        selector: (row) => row.lastUpdated,
        sortable: true,
        sortField: "lastUpdated",
      },
      {
        id: "enable log",
        name: "enable log".toLocaleUpperCase(),
        selector: (row) => row.lastUpdated,
        sortable: true,
        sortField: "lastUpdated",
      },
      {
        width: "40px",
        cell: renderAction,
        allowOverflow: true,
        button: true,
      },
    ];
  }, [deleteGateway, sn]);

  const createGateway = () => {
    // history.push(`/admin/sdwan/device/${sn}/gateways/configurations/create`);
  };

  return (
    <div>
      <div
        className="d-flex flex-wrap align-items-center justify-content-between pb-3 border-0 flex-md-nowrap"
        style={{ columnGap: 4 }}
      >
        <div className="d-flex justify-content-start align-items-top">
          <h4 className="text-muted mb-0" style={{ paddingTop: "8px" }}>
            Rules created by
          </h4>
          <UncontrolledDropdown>
            <DropdownToggle
              nav
              style={{ paddingLeft: "10px", paddingTop: "8px" }}
            >
              <h4 className="text-default mb-0">
                {selectedRuleCreatedBy}
                <i
                  className="fa fa-chevron-down fa-1x ml-2"
                  style={{ fontSize: "12px" }}
                ></i>
              </h4>
            </DropdownToggle>
            <DropdownMenu style={{ maxHeight: "120px", overflowY: "auto" }}>
              {["User", "System"].map((item, id) => (
                <DropdownItem
                  key={id}
                  onClick={() => setSelectedRuleCreatedBy(item)}
                >
                  <span className="text-dark">{item}</span>
                </DropdownItem>
              ))}
            </DropdownMenu>
          </UncontrolledDropdown>
        </div>

        <div className="d-flex flex-wrap align-items-center">
          <PrimeSearchInput
            onFilter={(text) => setSmartSearch(text)}
            className="flex-1"
            size="sm"
            tooltip="Press 'Enter' to search."
            tooltipOptions={{ position: "top" }}
          />
          <DeviceGatewayFilter sn={sn} />
          <RefreshButton onClick={dt.refetch} isLoading={dt.isFetching} />
          <Button
            size="md"
            color="primary"
            onClick={createGateway}
            style={{ borderRadius: "10px" }}
          >
            Create
          </Button>
        </div>
      </div>

      <div>
        <DataTable
          // data={dt.currentData}
          data={dummyData}
          defaultSortFieldId="statusTranslated"
          defaultSortAsc={false}
          responsive={true}
          columns={columns}
          persistTableHead
          noHeader
          striped
          progressPending={dt.isLoading || dt.isFetching}
          pagination
          paginationServer
          paginationTotalRows={dt.totalRows}
          paginationPerPage={dt.pageSize}
          onChangePage={dt.onChangePage}
          onChangeRowsPerPage={dt.onChangeRowsPerPage}
          sortServer
          onSort={dt.onSort}
          customStyles={{
            ...customStyles4,
            tableWrapper: {
              style: {
                minHeight: "400px",
              },
            },
          }}
        />
      </div>
    </div>
  );
};

export default FirewallRulesTabPanel;
