import { useCallback } from "react";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { useDeleteDeviceFirewallAliasMutation } from "../services";

export function useDeleteFirewallAlias(sn) {
  const [mutate] = useDeleteDeviceFirewallAliasMutation();

  return useCallback(
    (data) => {
      Swal.fire({
        title: `Delete ${data.name}?`,
        text: `This Firewall Alias will be permanently removed`,
        icon: "error",
        showCancelButton: true,
        confirmButtonText: "Yes, Delete",
        cancelButtonText: "No, Go Back",
        showLoaderOnConfirm: true,
        confirmButtonColor: "#2065AC",
        reverseButtons: true,
        preConfirm: async () => {
          try {
            await mutate({ sn, uuid: data.uuid });
            toast.success("Command to delete Firewall Alias sent.");
            return true;
          } catch (error) {
            Swal.showValidationMessage(`
              Request failed: ${error}
            `);
          }
        },
        allowOutsideClick: () => !Swal.isLoading(),
      });
    },
    [mutate, sn]
  );
}